.configCard .ant-card-body {
	height: 100% !important;
}

::-webkit-scrollbar {
	height: 10px !important;
}

.ant-switch.ant-switch-checked {
	background: #ed7d2dff;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
	background: #ed7d2dff;
}

button,
[type="button"] {
	background-color: rgba(0, 0, 0, 0.25);
}

.download-excel-button{
    border-radius: 8px;
    color: #fff;
    float: right;
    /* margin-bottom: 20px; */
    padding: 7px 15px;
    background-color: white;
    margin-right: 15px;
    /* border: 1px solid; */
    background-color: #EC7200;
}