  .save-button-container {
    margin-top: 20px;
    text-align: right;
  }
  
.access-management-container {
    /* padding: 20px; */
    background-color: #f5f5f5;
  }
  
  /* Title for the page */
  .access-management-container h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Styling for the search bar */
  .search-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
  }

  .search-container button {
    background-color: rgba(39, 166, 164, 1) !important;
    border-color: rgba(39, 166, 164, 1) !important;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    height: 40px;
    padding: 8px 16px;
    margin-right: 0;
    margin-top: 0;
    /* box-shadow: rgba(0, 0, 0, 0.04); */
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #fff;
  }
  
  .ant-input {
    width: 100%;
    /* max-width: 400px; */
  }
  
  /* Styling for the Ant Design Table */
  .ant-table {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Table header styling */
  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
    color: #333;
    font-weight: bold;
  }
  
  /* Table row styling */
  .ant-table-tbody > tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  .ant-table-tbody > tr:hover {
    background-color: #e6f7ff;
  }
  
  /* Pagination styling */
  .ant-pagination {
    margin-top: 20px;
  }
  
  /* Ant Design Table cell styling */
  .ant-table-cell {
    padding: 12px;
  }
  
  /* index.css */
/* index.css */
.access-type-container {
    display: flex;
    align-items: center;
  }
  
  .access-type-container .ant-select {
    margin-right: 8px;
  }
  
  .access-type-container .anticon-save {
    /* color: #1890ff; */
    cursor: pointer;
  }
  
  .tag-element{
    background-color: inherit !important;
    font-weight: none !important;
    color: inherit !important;
  }

  .add-user-button{
    background-color: rgba(39, 166, 164, 1) !important;
    border-color: rgba(39, 166, 164, 1) !important;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    height: 40px;
    padding: 8px 16px;
    margin-right: 0;
    margin-top: 0;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #fff;
  }

  .add-user-button:hover {
    background-color: rgba(39, 166, 164, 1) !important;
    border-color: rgba(39, 166, 164, 1) !important;
    font-size: 16px;
    font-weight: 400;
    border-radius: 2px;
    height: 40px;
    padding: 8px 16px;
    margin-right: 0;
    margin-top: 0;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #fff;
  }

  .users-table {
    /* box-shadow: rgba(144, 149, 160, 0.36) 0px 0px 8px 1px; */
    transform: scale(1);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 100ms;
    align-items: center;
    /* padding: 1rem; */
    margin: 20px;
    margin-top: 0;
  }

  .users-table .ant-table-thead .ant-table-cell {
    background: #171a1f !important;
    color: #ed7d2d !important;
    text-align: center !important;
}